import {formatDate, formatViewDate, toUtcMidnight} from '@matchsource/date';
import {ifNullable, isFutureDate, isPastDate, parseDateTime} from '@matchsource/utils';

import {
  CloseCaseModel,
  CloseCaseSimpleModel,
  DiagnosisModel,
  PatientInfoModel,
  PatientModel,
  TCInfoModel,
  TransplantTimelineHistoryModel,
} from './declarations';

export const isPatientId = (input: string): boolean => input && /^[\s\d-]+$/.test(input);

export const cleanPatientId = (patientId: string): string => patientId.replace(/\-/g, ''); // eslint-disable-line no-useless-escape

export const safeCleanPatientId = (patientId: string): string =>
  isPatientId(patientId) ? cleanPatientId(patientId) : patientId;

export function formatPatientId(patientIdRaw: string | number): string {
  if (!patientIdRaw) {
    return '';
  }

  let patientId = `${patientIdRaw}`;

  // if patient ID length is less than 6, pad with a prefixing '0':
  if (patientId.length === 6) {
    patientId = `0${patientId}`;
  }

  // If minimum length of chars is met, format the string:
  if (patientId.length >= 7) {
    patientId = `${patientId.substr(0, 3)}-${patientId.substr(3, 3)}-${patientId.substr(6)}`;
  }

  return patientId;
}

export function formatPatientInfo(data: PatientModel, transplantData: TransplantTimelineHistoryModel[]) {
  const unborn = data.unbornPatient || false;
  const bloodTypeCode = data.bloodTypeCode || null;
  const patientInfo: PatientInfoModel = {
    firstName: data.firstName || '',
    middleInitialName: data.middleInitialName || '',
    lastName: data.lastName || '',
    fullNameAlt: `${data.lastName}, ${data.firstName}`,
    sex: data.sex || null,
    unborn: data.unbornPatient || false,
    expectedDelivery: toUtcMidnight(data.expectedDeliveryDate),
    birthDate: toUtcMidnight(data.birthDateRaw || data.birthDate),
    birthDateFormatted: formatDate(data.birthDateRaw || data.birthDate),
    age: calculateAge(data.birthDate, unborn),
    weight: data.weight,
    bloodTypeCode: data.bloodTypeCode || null,
    abo: bloodTypeCode,
    rhType: data.rhType || null,
    cmvStatusCode: data.cmvStatusCode || null,
    raceCodes: data.raceCodes || [],
    ethnicityCode: data.ethnicityCode || null,
    ...formatTransplantTimelineHistoryData(transplantData),
    genderIdentityCode: data.genderIdentityCode,
    genderSelfIdentityText: data.genderSelfIdentityText,
  };

  return patientInfo;
}

export function formatTransplantTimelineHistoryData(transplantData: TransplantTimelineHistoryModel[]) {
  return {
    transplantTimelineHistoryCode:
      transplantData && transplantData.length ? getLatestTransplantEntry(transplantData).code : '',
    transplantTimelineHistoryDate:
      transplantData && transplantData.length ? formatViewDate(getLatestTransplantEntry(transplantData).date) : null,
  };
}

export function formatDiagnosis(data: PatientModel) {
  const diagnosisInfo: DiagnosisModel = {
    diagnosisCode: data.diagnosisCode || null,
    otherDiagnosis: data.otherDisease || '',
    secondaryDiagnosis: data.secondaryDiagnosis || '',
    diseaseStage: data.diseaseStage || null,
    numOfRemissions: ifNullable(data.remissionsCount, 0),
    diagnosisDate: toUtcMidnight(data.diagnosisDate) || '',
    transplantTimelineCode: data.transplantTimelineCode || null,
    transplantTimelineUpdated: data.transplantTimelineUpdated || false,
    preferredProductCode: data.preferredProductCode || null,
  };
  return diagnosisInfo;
}
export function formatTcInfo(data: PatientModel) {
  const refPhysician = data.refPhysician || '';
  const physician = data.physician || '';
  const sameAsPhysician = physician && refPhysician === physician;

  const tciInfo: TCInfoModel = {
    tcId: data.tcId || null,
    localId: data.localId || '',
    refId: data.refId,
    secondaryCode: data.secondaryCode || '',
    isDomesticSecondaryTc: data.domesticSecondaryTc || false,
    coordinatorId: data.coordinatorId || null,
    physician: physician,
    refPhysician: refPhysician,
    sameAsPhysician: sameAsPhysician,
    createdById: data.createdById,
    legacyCoordinator: data.tcCoordinatorName,
    patientProcess: data.patientProcess || null,
  };
  return tciInfo;
}

function getDifferenceInYears(from: string, to: number): number {
  return Math.floor(parseDateTime(to).diff(parseDateTime(from), 'years').years);
}

function calculateAge(birthDate: string, isUnborn = false): number | string {
  if (isUnborn) {
    return 'Unborn';
  }

  return !!birthDate && parseDateTime(birthDate).isValid && !isFutureDate(birthDate) && isPastDate(birthDate)
    ? getDifferenceInYears(birthDate, Date.now())
    : '';
}

function getLatestTransplantEntry(entries: TransplantTimelineHistoryModel[]): any {
  const clonedEntries = [...entries];
  return clonedEntries.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
}

export const convertToCaseCloseInfo = (patient: PatientModel, closeReason: CloseCaseSimpleModel): CloseCaseModel => ({
  caseResourceInfo: {
    emailId: patient.caseManagerEmail,
    firstName: patient.caseManagerFirstName,
    lastName: patient.caseManagerLastName,
    id: patient.caseManagerId,
  },
  closeReasonCde: closeReason.closeReasonCde,
  matchPreferenceCde: closeReason.matchPreferenceCde,
  otherReason: closeReason.otherReason,
});
